import React from 'react';
import classNames from 'classnames/bind';

import styles from './Section7.module.scss';

const cx = classNames.bind(styles);

const Section7 = () => {
  return (
    <div className={cx('container')}>
      <h1>
        DJ CLUB HOUSE
        <br />
        OPEN EVENT
      </h1>
      <p>
        DJ CLUB HOUSE 입장을 환영합니다!
        <br />
        이벤트 참여하고 다양한 사은품도 받아가세요!
      </p>
    </div>
  );
};

export default Section7;
