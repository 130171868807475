import React from 'react';
import classNames from 'classnames/bind';
import { useMediaQuery } from 'react-responsive';

import styles from './Section3.module.scss';

const cx = classNames.bind(styles);

const Section3 = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 735px)' });

  return (
    <div className={cx('container')}>
      <h1>CUCHARA IS</h1>
      <div className={cx('columns')}>
        <div className={cx('column')}>
          <div className={cx(['title', 'title1'])} />
          <div className={cx('mark')} />
          <p>
            신선하고 정직한 원재료,
            <br />
            매일 직접 조리하여 {isDesktop && <br />}
            완벽한 식사가 제공되는 공간
          </p>
        </div>
        <div className={cx('column')}>
          <div className={cx(['title', 'title2'])} />
          <div className={cx('mark')} />
          <p>
            고객은 우리의 패밀리이며,
            <br />
            패밀리의 이야기를 통해 {isDesktop && <br />}
            존중과 배려로 함께 {isDesktop && <br />}
            최고의 경험을 제공하는 공간
          </p>
        </div>
        <div className={cx('column')}>
          <div className={cx(['title', 'title3'])} />
          <div className={cx('mark')} />
          <p>
            음식을 통해 모두가 환영하며 {isDesktop && <br />}
            기억할 수 있는 {isDesktop && <br />}
            추억이 하나하나 쌓이는 공간
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section3;
