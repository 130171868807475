import React from 'react';
import classNames from 'classnames/bind';

import styles from './Section1.module.scss';

const cx = classNames.bind(styles);

const Section1 = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('limiter')}>
        <div className={cx('wrapper')}>
          <h1>CUCHARA</h1>
          <h2>DJ CLUB HOUSE</h2>
          <h3>BUILD UP YOUR TASTE</h3>
          <h4>더할수록 커지는 즐거움</h4>
          <div className={cx('logo')} />
        </div>
      </div>
    </div>
  );
};

export default Section1;
