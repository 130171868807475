import React from 'react';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router-dom';

import styles from './Section6.module.scss';

const cx = classNames.bind(styles);

const Section6 = () => {
  const history = useHistory();

  const moveTo = (uri) => {
    history.push(uri);
  };

  return (
    <div className={cx('container')} onClick={() => moveTo('/about-cuchara')}>
      <div className={cx('about')}>ABOUT</div>
      <div className={cx('title')}>CUCHARA</div>
    </div>
  );
};

export default Section6;
