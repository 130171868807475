import React from 'react';
import classNames from 'classnames/bind';
import { useMediaQuery } from 'react-responsive';

import styles from './Section4.module.scss';

const cx = classNames.bind(styles);

const Section4 = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 734px)' });

  const openDJWebsite = () => {
    window.open('http://www.bagagee.com/', '');
  };

  const openDJInstagram = () => {
    window.location.href = 'https://www.instagram.com/bagageeviphex13/';
  };

  return (
    <div className={cx('container')}>
      <h2>SEASON 01. DJ/PRODUCER</h2>
      <h1>DJ BAGAGEE VIPEX13</h1>
      <div className={cx('boxWrapper')}>
        <div className={cx('box')}>
          <div className={cx('top')}>
            <div className={cx('left')}>
              <div className={cx('title')}>
                <div>DJ BAGAGEE {isMobile && <br />}VIPEX13</div>
                <div>DJ/PRODUCER</div>
              </div>
              <div className={cx('meta')}>
                <div onClick={openDJWebsite}>www.bagagee.com</div>
                <div onClick={openDJInstagram}>
                  Instagram {isMobile && <br />}@bagageeviphex13
                </div>
              </div>
            </div>
            <div className={cx('right')} />
          </div>
          <div className={cx('bottom')}>
            <p>
              DJ BAGAGEE VIPEX13는 쿠차라와의 협업을 통해 각 원재료의 사운드를
              설정하고 해당 사운드들을 믹스한 DJ 플레이, 그리고 메인 메뉴들을
              대표하는 메인곡 제작과 함께 브랜드색과 음악 방향을 표현한 키비주얼
              작업을 함께 진행했습니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
