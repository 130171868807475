import React, { useState } from 'react';
import classNames from 'classnames/bind';
import ReactAudioPlayer from 'react-audio-player';
import ScrollTrigger from 'react-scroll-trigger';
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss';
import SwiperCore, { Lazy, Navigation, Autoplay } from 'swiper';
import { useMediaQuery } from 'react-responsive';

import styles from './Section3.module.scss';
import ingredients from '../../assets/datas/ingredients.json';

import black_bean from '../../assets/audios/black_bean.wav';
import brown_rice from '../../assets/audios/brown_rice.wav';
import cauliflower_rice from '../../assets/audios/cauliflower_rice.wav';
import fajita_vegetable from '../../assets/audios/fajita_vegetable.wav';
import galbi from '../../assets/audios/galbi.wav';
import green_chili from '../../assets/audios/green_chili.wav';
import guacamole from '../../assets/audios/guacamole.wav';
import kimchi_salsa from '../../assets/audios/kimchi_salsa.wav';
import romaine from '../../assets/audios/romaine.wav';
import shrimp from '../../assets/audios/shrimp.wav';
import steak from '../../assets/audios/steak.wav';
import tofu from '../../assets/audios/tofu.wav';
import tomato_salsa from '../../assets/audios/tomato_salsa.wav';

SwiperCore.use([Lazy, Navigation, Autoplay]);

const cx = classNames.bind(styles);

const audios = {
  black_bean,
  brown_rice,
  cauliflower_rice,
  fajita_vegetable,
  galbi,
  green_chili,
  guacamole,
  kimchi_salsa,
  romaine,
  shrimp,
  steak,
  tofu,
  tomato_salsa,
};

const Section3 = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1069px)' });

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [audio, setAudio] = useState(null);

  const handleClick = (index) => {
    if (index === selectedIndex) {
      setSelectedIndex(null);
      setAudio(null);
    } else {
      setSelectedIndex(index);
      setAudio(audios[ingredients[index].class]);
    }
  };

  const backgroundColor =
    selectedIndex !== null ? ingredients[selectedIndex].color : '#e3dabc';

  const ingredientClass =
    selectedIndex !== null ? ingredients[selectedIndex].class : null;

  const swiperParams = {
    slidesPerView: 'auto',
    spaceBetween: 0,
  };
  if (isDesktop) {
    swiperParams.autoplay = {
      delay: 2000,
      disableOnInteraction: false,
    };
  }

  return (
    <ScrollTrigger onExit={() => handleClick(selectedIndex)}>
      <div className={cx('container')} style={{ backgroundColor }}>
        <div className={cx(['lpWrapper', { off: selectedIndex === null }])}>
          <div className={cx(['center', ingredientClass])} />
          <div className={cx('turntable')}>
            <div className={cx(['ingres', 'top', ingredientClass])} />
            <div className={cx(['ingres', 'down', ingredientClass])} />
          </div>
          <div className={cx(['pin', { on: selectedIndex !== null }])} />
          {selectedIndex === null && (
            <div className={cx('message')}>
              원재료를 선택해서
              <br />
              비트를 확인해보세요.
            </div>
          )}
        </div>
        {audio && <ReactAudioPlayer src={audio} autoPlay loop />}
        <div className={cx('swiper')}>
          <Swiper {...swiperParams}>
            {ingredients.map((ingredient, index) => (
              <div
                key={index}
                className={cx(['menu', { selected: index === selectedIndex }])}
                onClick={() => handleClick(index)}
              >
                <div className={cx(['image', ingredient.class])} />
                <div className={cx('name')}>{ingredient.name}</div>
              </div>
            ))}
          </Swiper>
        </div>
      </div>
    </ScrollTrigger>
  );
};

export default Section3;
