import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import styles from './MenuBar.module.scss';

const cx = classNames.bind(styles);

const MenuBar = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 734px)' });
  const isHideTitle = useMediaQuery({
    query: '(min-width: 735px) and (max-width: 840px)',
  });
  const history = useHistory();
  const { pathname } = useLocation();
  const [isOpen, setOpen] = useState(false);
  const [isScrolled, setScrolled] = useState(false);

  const containerRef = useRef();

  useEffect(
    () => {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    window.scrollTo(0, 0);

    // GA Page View Event
    window.gtag('set', 'page_path', pathname);
    window.gtag('event', 'page_view');
  }, [pathname]);

  const handleScroll = (e) => {
    if (isMobile) return;

    const containerTop = window.pageYOffset - containerRef.current.offsetTop;
    setScrolled(containerTop > 100);
  };

  const moveToClub = () => {
    if (pathname === '/') {
      setOpen(false);
      enableScroll(true);
    }

    window.location.href = '/#club-house';
  };

  const moveTo = (uri) => {
    setOpen(false);
    enableScroll(true);

    if (uri === pathname) {
      window.scrollTo(0, 0);
    } else {
      history.push(uri);
    }
  };

  const toggleMenu = () => {
    setOpen(!isOpen);

    if (isOpen) {
      enableScroll(true);
    } else {
      enableScroll(false);
    }
  };

  const enableScroll = (isEnable) => {
    const body = document.getElementsByTagName('body')[0];

    if (isEnable) {
      body.classList.remove('not-scroll');
    } else {
      body.classList.add('not-scroll');
    }
  };

  return (
    <div ref={containerRef} className={cx(['container', { isScrolled }])}>
      <div className={cx('wrapper')}>
        <div className={cx('inner')}>
          <div className={cx('titleWrapper')} onClick={() => moveTo('/')}>
            <div className={cx('logo')} />
            {!isHideTitle && (
              <div className={cx('title')}>CUCHARA DJ CLUB HOUSE</div>
            )}
          </div>
          {/* Desktop, Tablet */}
          {!isMobile && (
            <div className={cx('desktopMenu')}>
              <div className={cx('menu')} onClick={moveToClub}>
                DJ CLUB HOUSE
              </div>
              <div className={cx('divider')} />
              <div
                className={cx('menu')}
                onClick={() => moveTo('/about-cuchara')}
              >
                ABOUT CUCHARA
              </div>
              <div className={cx('divider')} />
              <div className={cx('menu')} onClick={() => moveTo('/about-dj')}>
                ABOUT DJ
              </div>
            </div>
          )}
          {/* Mobile */}
          {isMobile && (
            <div className={cx('mobileMenu')}>
              <div className={cx(['menuPanel', { open: isOpen }])}>
                <div className={cx('menu')} onClick={() => moveTo('/')}>
                  HOME
                </div>
                <div className={cx('menu')} onClick={moveToClub}>
                  DJ CLUB HOUSE
                </div>
                <div
                  className={cx('menu')}
                  onClick={() => moveTo('/about-cuchara')}
                >
                  ABOUT CUCHARA
                </div>
                <div className={cx('menu')} onClick={() => moveTo('/about-dj')}>
                  ABOUT DJ
                </div>
              </div>
              <div
                className={cx(['menuButton', { open: isOpen }])}
                onClick={toggleMenu}
              >
                <div className={cx(['line', 'top'])} />
                <div className={cx(['line', 'middle'])} />
                <div className={cx(['line', 'bottom'])} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MenuBar;
