import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { useMediaQuery } from 'react-responsive';
import ScrollTrigger from 'react-scroll-trigger';
import Marquee from 'react-marquee-slider';

import styles from './Section2.module.scss';
import beatsJson from '../../assets/datas/beats.json';
import commentsBeat1Json from '../../assets/datas/comments-beat1.json';
import commentsBeat2Json from '../../assets/datas/comments-beat2.json';
import commentsBeat3Json from '../../assets/datas/comments-beat3.json';
import commentsBeat4Json from '../../assets/datas/comments-beat4.json';
import beat1 from '../../assets/beats/chicken_burrito.mp3';
import beat2 from '../../assets/beats/galbi_burrito_bowl.mp3';
import beat3 from '../../assets/beats/steak_taco.mp3';
import beat4 from '../../assets/beats/tofu_salad.mp3';

const cx = classNames.bind(styles);

const MarqueeItem = <div className={cx('item')}>PLAY YOUR CHOICE</div>;
const MarqueeItemSet = (
  <div key="itemSet" className={cx('itemSet')}>
    {MarqueeItem}
    {MarqueeItem}
    {MarqueeItem}
    {MarqueeItem}
    {MarqueeItem}
    {MarqueeItem}
    {MarqueeItem}
    {MarqueeItem}
    {MarqueeItem}
    {MarqueeItem}
  </div>
);

const Section2 = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 848px)' });

  const [mobilePlayerFixedTop, setMobilePlayerFixedTop] = useState({
    top: false,
    bottom: false,
  });
  const [beats, setBeats] = useState(beatsJson);
  const [beatIndex, setBeatIndex] = useState(0);
  const [playingBeat, setPlayingBeat] = useState(null);

  const containerRef = useRef();
  const mobilePlaylistRef = useRef();
  const playerRef = useRef();
  const fillRef = useRef();
  const durationRef = useRef();

  useEffect(
    () => {
      const bootstrapAsync = async () => {
        beats[0].comments = commentsBeat1Json;
        beats[1].comments = commentsBeat2Json;
        beats[2].comments = commentsBeat3Json;
        beats[3].comments = commentsBeat4Json;

        setBeats([...beats]);
      };
      bootstrapAsync();

      playerRef.current.ontimeupdate = (e) => {
        const progress =
          (playerRef.current.currentTime / playerRef.current.duration) * 100;
        fillRef.current.style.width = `${progress}%`;

        // 현재 재생시간 업데이트
        const date1 = new Date(null);
        date1.setSeconds(Math.floor(playerRef.current.currentTime));
        fillRef.current.innerText = date1.toISOString().substr(14, 5);

        // 전체 재생시간 업데이트
        const date2 = new Date(null);
        date2.setSeconds(Math.floor(playerRef.current.duration));
        durationRef.current.innerText = date2.toISOString().substr(14, 5);
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleScroll = (e) => {
    if (!isMobile) return;

    const containerTop = window.pageYOffset - containerRef.current.offsetTop;
    const containerBottom =
      containerTop -
      containerRef.current.clientHeight +
      mobilePlaylistRef.current.clientHeight;

    setMobilePlayerFixedTop({
      top: containerTop > 0 && containerBottom < 0,
      bottom: containerBottom > 0,
    });
  };

  const toggleBeat = (index) => {
    if (playingBeat && beatIndex === index) {
      pause();
    } else {
      playBeat(index);
    }
  };

  const seekTime = (e) => {
    playerRef.current.currentTime =
      (playerRef.current.duration * e.clientX) / window.innerWidth;
  };

  const pause = () => {
    playerRef.current.currentTime = 0;
    playerRef.current.pause();
    setPlayingBeat(null);
  };

  const playBeat = (index) => {
    setBeatIndex(index);

    let nextPlayingBeat;
    switch (index) {
      case 0:
        nextPlayingBeat = beat1;
        break;
      case 1:
        nextPlayingBeat = beat2;
        break;
      case 2:
        nextPlayingBeat = beat3;
        break;
      case 3:
        nextPlayingBeat = beat4;
        break;
      default:
        break;
    }
    setPlayingBeat(nextPlayingBeat);
  };

  const mobilePlaylistClasses = [
    'mobilePlaylist',
    cx('mobilePlayer'),
    cx({ fixedTop: mobilePlayerFixedTop.top }),
    cx({ fixedBottom: mobilePlayerFixedTop.bottom }),
  ];

  return (
    <ScrollTrigger onExit={pause}>
      <div
        id="club-house"
        ref={containerRef}
        className={cx('container')}
        style={{ backgroundColor: beats[beatIndex].backgroundColor }}
      >
        {isMobile && (
          <div
            ref={mobilePlaylistRef}
            className={mobilePlaylistClasses.join(' ')}
          >
            <div className={cx(['marquee', { hide: playingBeat !== null }])}>
              <Marquee velocity={10}>{[MarqueeItemSet]}</Marquee>
            </div>
            <div
              className={cx(['playback', { hide: playingBeat === null }])}
              onClick={seekTime}
            >
              <div ref={fillRef} className={cx('fill')} />
              <div ref={durationRef} className={cx('duration')} />
            </div>
            <div className={cx('wrapper')}>
              {beats.map((beat, index) => {
                const isOn = beatIndex === index;
                const isPlaying = playingBeat && isOn;
                const backgroundColor = isOn ? beat.backgroundColor : '#E3DCBF';
                const color = isOn ? 'white' : 'black';
                const nameLines = beat.nameEn.split(' ');

                return (
                  <div
                    key={index}
                    className={cx('beatItem')}
                    style={{ backgroundColor }}
                    onClick={() => toggleBeat(index)}
                  >
                    <div
                      className={cx([
                        'thumb',
                        `menu${index + 1}`,
                        { on: isOn },
                        { playing: isPlaying },
                      ])}
                    />
                    <div className={cx('name')} style={{ color }}>
                      {nameLines.map((nameLine) => (
                        <>
                          {nameLine}
                          <br />
                        </>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div className={cx('top')}>
          <div className={cx('wrapper')}>
            <div className={cx('left')}>
              <h1>{beats[beatIndex].nameEn}</h1>
              <div className={cx('likeWrapper')}>
                <h2>{beats[beatIndex].nameKr}</h2>
              </div>
              <div className={cx('imageWrapper')}>
                <div className={cx(['bg', `bg${beatIndex + 1}`])} />
                <div
                  className={cx([
                    [
                      'record',
                      `menu${beatIndex + 1}`,
                      { active: playingBeat !== null },
                    ],
                  ])}
                />
                <div className={cx(['menu', `menu${beatIndex + 1}`])} />
              </div>
            </div>
            <div className={cx('right')}>
              <div className={cx('row')}>
                <div className={cx('item')}>
                  <div className={cx('label')}>GENRE</div>
                  <p>{beats[beatIndex].genre}</p>
                </div>
                <div className={cx('item')}>
                  <div className={cx('label')}>BPM</div>
                  <p>{beats[beatIndex].bpm} BPM</p>
                </div>
                <div className={cx('item')}>
                  <div className={cx('label')}>ALBUM NOTE</div>
                  <p>{beats[beatIndex].album_note}</p>
                </div>
              </div>
              <div className={cx('row')}>
                <div className={cx('item')}>
                  <div className={cx('label')}>COMMENTS</div>
                </div>
              </div>
              {!isMobile && (
                <div className={cx('list')}>
                  {beats[beatIndex].comments?.map((comment, index) => {
                    const length = comment.phone.length;
                    const phone = comment.phone.substring(length - 4, length);

                    return (
                      <div key={index} className={cx('row')}>
                        <div className={cx('phone')}>{phone}</div>
                        <div className={cx('comment')}>{comment.text}</div>
                      </div>
                    );
                  })}
                </div>
              )}
              {isMobile && (
                <div className={cx('mobileList')}>
                  {beats[beatIndex].comments?.map((comment, index) => {
                    const length = comment.phone.length;
                    const phone = comment.phone.substring(length - 4, length);

                    return (
                      <div key={index} className={cx('item')}>
                        <div className={cx('phone')}>{phone}</div>
                        <div className={cx('comment')}>{comment.text}</div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        {!isMobile && (
          <>
            <div className={cx(['marquee', { hide: playingBeat !== null }])}>
              <Marquee velocity={isMobile ? 10 : 40}>
                {[MarqueeItemSet]}
              </Marquee>
            </div>
            <div
              className={cx(['playback', { hide: playingBeat === null }])}
              onClick={seekTime}
            >
              <div ref={fillRef} className={cx('fill')} />
              <div ref={durationRef} className={cx('duration')} />
            </div>
            <div className={cx('bottom')}>
              <div className={cx('left')}>
                <div className={cx(['image', `menu${beatIndex + 1}`])} />
                <div className={cx('controlWrapper')}>
                  <div className={cx('titleEn')}>{beats[beatIndex].nameEn}</div>
                  <div className={cx('titleKr')}>{beats[beatIndex].nameKr}</div>
                  <div className={cx('buttons')}>
                    <div className={cx(['button', 'pause'])} onClick={pause} />
                  </div>
                </div>
              </div>
              <div className={cx('right')}>
                <div className={cx('titleWrapper')}>
                  <div className={cx('titleKr')}>쿠차라 테마음원 듣기</div>
                </div>
                <div className={cx('beatList')}>
                  {beats.map((beat, index) => (
                    <div
                      key={index}
                      className={cx('beatItem')}
                      onClick={() => toggleBeat(index)}
                    >
                      <div
                        className={cx([
                          'thumb',
                          `menu${index + 1}`,
                          { on: beatIndex === index },
                        ])}
                      />
                      <div
                        className={cx('title')}
                        style={{
                          color:
                            beatIndex === index
                              ? beats[index].backgroundColor
                              : 'white',
                        }}
                      >
                        {beat.nameEn}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
        <audio
          ref={playerRef}
          autoPlay
          src={playingBeat}
          onEnded={pause}
        ></audio>
      </div>
    </ScrollTrigger>
  );
};

export default Section2;
