import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import MenuBar from './components/MenuBar';
import ScrollToTop from './components/ScrollToTop';
import Home from './pages/Home';
import Cuchara from './pages/Cuchara';
import DJ from './pages/DJ';

const App = () => {
  return (
    <BrowserRouter>
      <MenuBar />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/about-cuchara">
          <Cuchara />
        </Route>
        <Route exact path="/about-dj">
          <DJ />
        </Route>
      </Switch>
      <ScrollToTop />
    </BrowserRouter>
  );
};

export default App;
