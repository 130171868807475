import React from 'react';

import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section6 from './Section6';

const DJ = () => {
  return (
    <div>
      <Section1 /> {/* Main */}
      <Section2 /> {/* CUCHARA DJ CLUB HOUSE */}
      <Section3 /> {/* DJ CUCHARA PLAYLIST */}
      <Section4 /> {/* DJ BAGAGEE VIPEX13 */}
      <Section6 /> {/* ABOUT CUCHARA */}
    </div>
  );
};

export default DJ;
