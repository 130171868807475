import React from 'react';
import classNames from 'classnames/bind';

import styles from './Section4.module.scss';

const cx = classNames.bind(styles);

const Section4 = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <div className={cx('padding')}>
          <div className={cx('logo')} />
          <h1>ENJOY YOUR CHOICE</h1>
          <h2>
            메뉴타입부터 소스까지,
            <br />
            날마다 새로운 선택을 즐겨보세요!
          </h2>
          {/* Step 1, 2, 3, 4 */}
          <div className={cx('steps')}>
            <div className={cx('stepWrapper')}>
              <div className={cx('label')}>STEP 1</div>
              <div className={cx('titleWrapper')}>
                <div className={cx('title')}>MAIN TYPE CHOICES</div>
              </div>
              <div className={cx(['menu', 'step1'])} />
            </div>
            <div className={cx('stepWrapper')}>
              <div className={cx('label')}>STEP 2</div>
              <div className={cx('titleWrapper')}>
                <div className={cx('title')}>RICE & BEANS</div>
              </div>
              <div className={cx(['menu', 'step2'])} />
            </div>
            <div className={cx('stepWrapper')}>
              <div className={cx('label')}>STEP 3</div>
              <div className={cx('titleWrapper')}>
                <div className={cx('title')}>MEAT / TOFU CHOICES</div>
              </div>
              <div className={cx(['menu', 'step3-1'])} />
              <div className={cx(['menu', 'step3-2'])} />
            </div>
            <div className={cx('stepWrapper')}>
              <div className={cx('label')}>STEP 4</div>
              <div className={cx('titleWrapper')}>
                <div className={cx('title')}>SALSAS</div>
              </div>
              <div className={cx(['menu', 'step4'])} />
            </div>
          </div>
          {/* Menu */}
          <div className={cx('menuWrapper')}>
            <div className={cx('menuCell')}>
              <div className={cx(['name', 'name1'])}>
                BURRITO BOWL
                <br />
                <span>부리또 볼</span>
              </div>
              <div className={cx(['menu', 'menu1'])} />
            </div>
            <div className={cx('menuCell')}>
              <div className={cx(['name', 'name2'])}>
                BURRITO
                <br />
                <span>부리또</span>
              </div>
              <div className={cx(['menu', 'menu2'])} />
            </div>
            <div className={cx('menuCell')}>
              <div className={cx(['name', 'name3'])}>
                TACO
                <br />
                <span>타코</span>
              </div>
              <div className={cx(['menu', 'menu3'])} />
            </div>
            <div className={cx('menuCell')}>
              <div className={cx(['name', 'name4'])}>
                SALAD
                <br />
                <span>샐러드</span>
              </div>
              <div className={cx(['menu', 'menu4'])} />
            </div>
          </div>
          {/* Tip */}
          <div className={cx('tipWrapper')}>
            <div className={cx('label')}>TIP</div>
            <h1>CUCHARA’S CHOICE</h1>
            <p>
              ‘쿠차라 초이스’라고 외치세요.
              <br />
              유쾌한 쿠차라 크루들의 추천 레시피로 만들어 드립니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
