import React from 'react';
import classNames from 'classnames/bind';

import styles from './ScrollToTop.module.scss';

const cx = classNames.bind(styles);

const ScrollToTop = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return <div className={cx('container')} onClick={scrollToTop} />;
};

export default ScrollToTop;
