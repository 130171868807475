import React from 'react';

import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';
import Section6 from './Section6';

const Cuchara = () => {
  return (
    <div>
      <Section1 /> {/* Main */}
      <Section2 /> {/* EAT FRESH, STAY HEALTHY */}
      <Section3 /> {/* CUCHARA IS */}
      <Section4 /> {/* Enjoy Your Choice */}
      <Section5 /> {/* Location */}
      <Section6 /> {/* ABOUT DJ */}
    </div>
  );
};

export default Cuchara;
