import React from 'react';
import classNames from 'classnames/bind';
import { Timeline, Tween } from 'react-gsap';
import Marquee from 'react-marquee-slider';
import { useMediaQuery } from 'react-responsive';

import styles from './Section1.module.scss';

const cx = classNames.bind(styles);

const MarqueeItem = (
  <div className={cx('item')}>
    <span>WELCOME TO</span> CUCHARA DJ CLUB HOUSE
  </div>
);

const MarqueeItemSet = (
  <div key="itemSet" className={cx('itemSet')}>
    {MarqueeItem}
    {MarqueeItem}
    {MarqueeItem}
    {MarqueeItem}
    {MarqueeItem}
    {MarqueeItem}
  </div>
);

const Section1 = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 734px)' });

  return (
    <div className={cx('container')}>
      <div className={cx('eq')} />
      <div className={cx('topic')}>
        <div className={cx('logo')} />
        <h1>BUILD UP YOUR TASTE</h1>
        <h2>더할수록 커지는 {isMobile && <br />}즐거움</h2>
      </div>
      <Timeline>
        <Tween duration={0.4} />
        <Tween
          from={{ top: '100vh', scaleX: 0, scaleY: 0 }}
          to={{ top: 0, scaleX: 1, scaleY: 1 }}
          duration={0.4}
        >
          <div className={cx('speakers')}>
            <div className={cx(['speaker', 'left'])} />
            <div className={cx(['speaker', 'right'])} />
          </div>
        </Tween>
        <Tween from={{ top: '100vh' }} to={{ top: 0 }} duration={0.4}>
          <div className={cx('accessories')}>
            <div className={cx(['accessory', 'earphone'])} />
            <div className={cx(['accessory', 'megaphone'])} />
          </div>
        </Tween>
      </Timeline>
      <div className={cx('plate')} />
      <div className={cx('turntable')}>
        <div className={cx('pin')} />
      </div>
      {!isMobile && (
        <div className={cx('marquee')}>
          <Marquee velocity={40}>{[MarqueeItemSet]}</Marquee>
        </div>
      )}
    </div>
  );
};

export default Section1;
