import React from 'react';
import classNames from 'classnames/bind';
import { useMediaQuery } from 'react-responsive';

import styles from './Section2.module.scss';

const cx = classNames.bind(styles);

const Section2 = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 734px)' });

  return (
    <div className={cx('container')}>
      <h1>CUCHARA DJ CLUB HOUSE</h1>
      <p>
        음악 사운드를 통해 귀로 느끼는
        <br />
        신선한 자극, 새로움이 이제는 {isMobile && <br />}
        <strong>쿠차라의 나만의 조합</strong>을 통해
        <br />
        미각으로 느끼는 자유로움으로 다가갑니다.
      </p>
      <div className={cx('boxWrapper')}>
        <div className={cx('box')}>
          <h2>DJ CUCHARA PROJECT</h2>
          <p>
            DJ CUCHARA PROJECT는 그날의 기분에 따라 소스부터 토핑까지 모든
            재료를 자유롭게 조합하는 쿠차라의 경험을 DJ 비트에 담은
            프로젝트입니다. 쿠차라의 메인 원재료의 특징을 담은 개별 비트를
            만들고, 각각 원재료가 만나 보다 맛있는 맛을 만드는 과정을 쿠차라의
            비트의 조합을 통해 4가지 메인음원에 담았습니다.{' '}
            {isMobile && (
              <>
                <br />
                <br />
              </>
            )}
            시즌별 추천 DJ PLAYLIST는 매장에서 느끼는 쿠차라의 모든 경험을 더욱
            다이나믹 하게합니다.
            <br />
            <br />첫 번째 시작을 함께한 DJ BAGAGEE VIPEX13이 만든 쿠차라 메인
            음원과 그의 감각으로 선정된 PLAYLIST를 즐겨보세요. 또한 쿠차라 DJ
            CUCHARA PROJECT의 음악은 시즌별 협업 DJ, 쿠차라의 크루, 그리고
            여러분과 함께합니다. 모두가 함께 어우러져 더할수록 커지는 즐거움을
            느껴보세요.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section2;
