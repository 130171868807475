import React, { useState } from "react";
import classNames from "classnames/bind";
import ScrollTrigger from "react-scroll-trigger";
import { useMediaQuery } from "react-responsive";

import styles from "./Section5.module.scss";
import ProgressRing from "../../components/ProgressRing";

const cx = classNames.bind(styles);

const Section5 = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 734px)" });

  const [isLoading, setLoading] = useState(false);
  const [isShowPlayer, setShowPlayer] = useState(false);

  const autoStartVideo = () => {
    if (!isMobile) {
      setLoading(true);
      setTimeout(() => {
        setShowPlayer(true);
      }, 2000);
    }
  };

  const showPlayer = () => {
    setShowPlayer(true);
  };

  const stopVideo = () => {
    setLoading(false);
    setShowPlayer(false);
  };

  return (
    <ScrollTrigger onEnter={autoStartVideo} onExit={stopVideo}>
      <div className={cx("container")}>
        <div className={cx("layer")}>
          <h1>BUILD UP {isMobile && <br />}YOUR TASTE</h1>
          <h2>더할수록 커지는 즐거움</h2>
          <h3>MAKING STORY {isMobile && <br />}WITH DJ BAGAGEE VIPEX13</h3>
          {!isMobile && (
            <div className={cx("button")} onClick={showPlayer}>
              <ProgressRing on={isLoading} />
            </div>
          )}
        </div>
        <div className={cx("playerWrapper")}>
          {(isMobile || isShowPlayer) && (
            <>
              <iframe
                src="https://player.vimeo.com/video/946972974?badge=0&amp;autoplay=1&amp;loop=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                title="DJ Club House"
              ></iframe>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </>
          )}
        </div>
      </div>
    </ScrollTrigger>
  );
};

export default Section5;
