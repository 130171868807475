import React from 'react';
import classNames from 'classnames/bind';

import styles from './Section1.module.scss';

const cx = classNames.bind(styles);

const Section1 = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <h1>CUCHARA</h1>
        <h2>MEXICAN EATERY</h2>
        <h3>
          쿠차라는 신선한 재료로 매일 조리하는 건강한 멕시칸 브랜드입니다.
        </h3>
      </div>
    </div>
  );
};

export default Section1;
