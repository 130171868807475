import React from 'react';

import './ProgressRing.scss';

const ProgressRing = ({ on }) => {
  if (!on) {
    return null;
  }

  return (
    <div className="circle_progress">
      <div className="left">
        <div className="bar" />
      </div>
      <div className="right">
        <div className="bar" />
      </div>
      <div className="value" />
    </div>
  );
};

export default ProgressRing;
