import React from 'react';
import classNames from 'classnames/bind';
import { useMediaQuery } from 'react-responsive';

import styles from './Section3.module.scss';

const cx = classNames.bind(styles);

const Section3 = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 734px)' });

  return (
    <div className={cx('container')}>
      <h1>DJ CUCHARA PLAYLIST</h1>
      <p>
        DJ CUCHARA 선정! {isMobile && <br />}지금 쿠차라 매장에서 플레이 되고
        있는 곡입니다.
      </p>
      <div className={cx('columns')}>
        {/* MORNING SALAD */}
        <div className={cx('column')}>
          <h2 className={cx('title1')}>MORNING SALAD</h2>
          <p>
            활기찬 아침 쿠차라 샐러드로 하루를 여는
            {isMobile && <br />}
            감성을 담은 하우스 비트의 곡
          </p>
          <ul>
            <li>
              <div className={cx(['thumb', 'thumb1'])} />
              <div className={cx('titleWrapper')}>
                <div className={cx('singer')}>Amtrac</div>
                <div className={cx('title')}>Formal Disco</div>
              </div>
            </li>
            <li>
              <div className={cx(['thumb', 'thumb1'])} />
              <div className={cx('titleWrapper')}>
                <div className={cx('singer')}>Blondee</div>
                <div className={cx('title')}>I Love You</div>
              </div>
            </li>
            <li>
              <div className={cx(['thumb', 'thumb1'])} />
              <div className={cx('titleWrapper')}>
                <div className={cx('singer')}>
                  Satin Jackets, Kids At Midnight
                </div>
                <div className={cx('title')}>Say You</div>
              </div>
            </li>
            <li>
              <div className={cx(['thumb', 'thumb1'])} />
              <div className={cx('titleWrapper')}>
                <div className={cx('singer')}>Supernova</div>
                <div className={cx('title')}>Lovely Summer Dreams</div>
              </div>
            </li>
            <li>
              <div className={cx(['thumb', 'thumb1'])} />
              <div className={cx('titleWrapper')}>
                <div className={cx('singer')}>Capital Cities</div>
                <div className={cx('title')}>Safe and Sound</div>
              </div>
            </li>
          </ul>
        </div>
        {/* AFTERNOON BOWL */}
        <div className={cx('column')}>
          <h2 className={cx('title2')}>AFTERNOON BOWL</h2>
          <p>
            바쁜 점심 잠깐의 여유를 느낄 수 있는
            {isMobile && <br />}
            느린 비트의 곡
          </p>
          <ul>
            <li>
              <div className={cx(['thumb', 'thumb2'])} />
              <div className={cx('titleWrapper')}>
                <div className={cx('singer')}>boerd, Stella Explorer</div>
                <div className={cx('title')}>Before We Drown</div>
              </div>
            </li>
            <li>
              <div className={cx(['thumb', 'thumb2'])} />
              <div className={cx('titleWrapper')}>
                <div className={cx('singer')}>Isadora</div>
                <div className={cx('title')}>The Best</div>
              </div>
            </li>
            <li>
              <div className={cx(['thumb', 'thumb2'])} />
              <div className={cx('titleWrapper')}>
                <div className={cx('singer')}>Audio Jacker</div>
                <div className={cx('title')}>Turn Up The Music</div>
              </div>
            </li>
            <li>
              <div className={cx(['thumb', 'thumb2'])} />
              <div className={cx('titleWrapper')}>
                <div className={cx('singer')}>Lazy J</div>
                <div className={cx('title')}>Feelings</div>
              </div>
            </li>
            <li>
              <div className={cx(['thumb', 'thumb2'])} />
              <div className={cx('titleWrapper')}>
                <div className={cx('singer')}>
                  Keyshia Cole, Missy Elliott, Lil' Kim
                </div>
                <div className={cx('title')}>Let It Go</div>
              </div>
            </li>
          </ul>
        </div>
        {/* EVENING TACOS PARTY */}
        <div className={cx('column')}>
          <h2 className={cx('title3')}>EVENING TACOS PARTY</h2>
          <p>
            하루 일과를 마치고 친구들과 함께 즐기는
            {isMobile && <br />}
            기분을 담은 경쾌한 비트의 곡
          </p>
          <ul>
            <li>
              <div className={cx(['thumb', 'thumb3'])} />
              <div className={cx('titleWrapper')}>
                <div className={cx('singer')}>Richard Grey, Lissat</div>
                <div className={cx('title')}>Everyday Jumping</div>
              </div>
            </li>
            <li>
              <div className={cx(['thumb', 'thumb3'])} />
              <div className={cx('titleWrapper')}>
                <div className={cx('singer')}>Flashmob</div>
                <div className={cx('title')}>Closer</div>
              </div>
            </li>
            <li>
              <div className={cx(['thumb', 'thumb3'])} />
              <div className={cx('titleWrapper')}>
                <div className={cx('singer')}>
                  Riton, Nightcrawlers, Mufasa & Hypeman
                </div>
                <div className={cx('title')}>Friday</div>
              </div>
            </li>
            <li>
              <div className={cx(['thumb', 'thumb3'])} />
              <div className={cx('titleWrapper')}>
                <div className={cx('singer')}>Little big</div>
                <div className={cx('title')}>Tacos</div>
              </div>
            </li>
            <li>
              <div className={cx(['thumb', 'thumb3'])} />
              <div className={cx('titleWrapper')}>
                <div className={cx('singer')}>
                  Purple Disco Machine, Moss Kena, The Knocks
                </div>
                <div className={cx('title')}>Fireworks</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Section3;
