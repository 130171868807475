import React from 'react';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router-dom';

import styles from './Section6.module.scss';

const cx = classNames.bind(styles);

const Section6 = () => {
  const history = useHistory();

  const moveTo = (uri) => {
    history.push(uri);
  };

  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <div className={cx('cuchara')}>
          <div className={cx('underLayer')}>
            <div className={cx('about')}>ABOUT</div>
            <div className={cx('title')}>CUCHARA</div>
          </div>
          <div
            className={cx('overLayer')}
            onClick={() => moveTo('/about-cuchara')}
          >
            <div className={cx('logo')} />
          </div>
        </div>
        <div className={cx('dj')}>
          <div className={cx('underLayer')}>
            <div className={cx('about')}>ABOUT</div>
            <div className={cx('title')}>DJ</div>
          </div>
          <div className={cx('overLayer')} onClick={() => moveTo('/about-dj')}>
            <div className={cx('logo')} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section6;
