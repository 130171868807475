import React, { useEffect } from 'react';
import ScrollTrigger from '@terwanerik/scrolltrigger';

// import Loading from '../../components/Loading';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';
import Section6 from './Section6';
import Section7 from './Section7';

const trigger = new ScrollTrigger();

const Home = () => {
  useEffect(() => {
    trigger.add('[data-trigger]', { once: false });
  }, []);

  return (
    <div>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      {/* <Loading /> */}
    </div>
  );
};

export default Home;
