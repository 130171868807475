import React from "react";
import classNames from "classnames/bind";

import styles from "./Section2.module.scss";

const cx = classNames.bind(styles);

const Section2 = () => {
  return (
    <div className={cx("container")}>
      <div className={cx("logo")} />
      <div className={cx("titleWrapper")}>
        <div className={cx("title")} />
      </div>
      <p>
        부리또, 부리또볼, 타코, 샐러드 4종의 메뉴 타입에 8개의 맛있는 메인 토핑!
        <br />
        라이스부터 소스까지 18가지의 선택재료를 통해 나만의 선택으로 더욱 특별한
        메뉴를 즐겨보세요.
        <br />
        오픈된 주방에서 매일 신선한 재료 손질부터 조리까지, 쿠차라를 경험한다는
        것은 새로운 즐거움입니다.
      </p>
      <div className={cx("widthLimiter")}>
        <div className={cx("padding")}>
          <div className={cx("playerWrapper")}>
            <iframe
              src="https://player.vimeo.com/video/946989958?badge=0&amp;autoplay=1&amp;loop=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              title="Cuchara"
            ></iframe>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section2;
