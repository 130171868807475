import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { PlayState, Tween, Timeline } from 'react-gsap';
import { useMediaQuery } from 'react-responsive';
import ScrollTrigger from 'react-scroll-trigger';

import styles from './Section4.module.scss';

const cx = classNames.bind(styles);

const sentenceFrom = {
  opacity: 0,
  y: '16px',
};

const sentenceTo = {
  opacity: 0,
  y: '-40px',
  scale: 1.1,
};

const Section4 = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 734px)' });

  const [playState, setPlayState] = useState(PlayState.stop);

  const SentenceWrapper1 = (
    <div className={cx('sentenceWrapper')}>
      <h1>
        WELCOME TO
        <br />
        CUCHARA {isMobile && <br />}DJ CLUB HOUSE
      </h1>
      <p>
        예상못한 비트들의 조합으로 탄생되는 인생곡처럼 {!isMobile && <br />}
        소스부터 토핑까지 모든 재료를 DJ가 비트를 조합하듯 {!isMobile && <br />}
        자유롭게 선택해보세요.
      </p>
    </div>
  );

  const SentenceWrapper2 = (
    <div className={cx('sentenceWrapper')}>
      <p>
        쿠차라에서 즐길 수 있는 선택의 재미가 원재료별 사운드에 담겨{' '}
        {!isMobile && <br />}
        귀로 느끼는 신선한 자극으로 다가갑니다.
      </p>
    </div>
  );

  const SentenceWrapper3 = (
    <div className={cx('sentenceWrapper')}>
      <p>
        쿠차라의 다양함이 가장 맛있게 표현된 {!isMobile && <br />}
        DJ CLUB HOUSE 테마음원, 지금 플레이해보세요!
      </p>
    </div>
  );

  const startTimeline = () => {
    setPlayState(PlayState.restart);
  };

  const stopTimeline = () => {
    setPlayState(PlayState.stop);
  };

  return (
    <ScrollTrigger onEnter={startTimeline} onExit={stopTimeline}>
      <div data-trigger className={cx('container')}>
        <Timeline
          playState={playState}
          wrapper={<div className={cx('pinContainer')} />}
        >
          {/* Take 1 */}
          <div className={cx(['bg', 'bg1'])}>
            <div className={cx(['deco', 'deco1'])} />
            <div className={cx(['deco', 'deco2'])} />
            <div className={cx(['deco', 'deco3'])} />
          </div>
          <Timeline target={SentenceWrapper1}>
            <Tween from={sentenceFrom} />
            <Tween />
            <Tween />
            <Tween to={sentenceTo} />
          </Timeline>
          {/* Take 2 */}
          <Tween from={{ opacity: 0 }}>
            <div className={cx(['bg', 'bg2'])}>
              <div className={cx(['deco', 'deco1'])} />
              <div className={cx(['deco', 'deco2'])} />
              <div className={cx(['deco', 'deco3'])} />
              <div className={cx(['deco', 'deco4'])} />
            </div>
          </Tween>
          <Timeline target={SentenceWrapper2}>
            <Tween from={sentenceFrom} />
            <Tween />
            <Tween to={sentenceTo} />
          </Timeline>
          {/* Take 3 */}
          <Tween from={{ opacity: 0 }}>
            <div className={cx(['bg', 'bg3'])}>
              <div className={cx(['deco', 'deco1'])} />
            </div>
          </Tween>
          <Timeline target={SentenceWrapper3}>
            <Tween from={sentenceFrom} />
          </Timeline>
        </Timeline>
      </div>
    </ScrollTrigger>
  );
};

export default Section4;
